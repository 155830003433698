<template>
  <div class="table-responsive assets table-responsive-md">
    <b-modal id="modal-1" title="Customize Asset" v-on:ok="saveAssetConfig()">
      <p v-if="currentAsset">
        <span>Customize Mappamundi settings for asset</span> <code>{{ currentAsset.key }}</code
        ><span> in collection <code>{{ collection }} </code> and source <code> {{ source }}</code>.</span>
      </p>
      <b-form-group>
        <div>
          Selected color: <strong>{{ color }}</strong>
        </div>
        <b-form-select v-model="color" :select-size="5">
          <template v-for="color in colors">
            <b-form-select-option :value="color" class="colors" :style="{ background: 'var(--' + color + ')' }">
              <span>{{ color }}</span>
            </b-form-select-option>
            >
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Select scaling:">
        <b-form-row>
          <b-form-radio-group v-model="mode" @change="onChangeMode">

             <b-form-radio  value="percentile"
            >Percentile</b-form-radio
          >

          <b-form-radio value="min/max"
            >Min/Max</b-form-radio
          >
          </b-form-radio-group>
         
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Min" label-for="modal-min" label-cols="2">
              <b-form-input
                v-model="min"
                id="modal-min"
                :min="mode == 'percentile' ? 0 : null"
                :max="mode == 'percentile' ? 100 : null"
                type="number"
                step=".01"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Max" label-for="modal-max" label-cols="2">
              <b-form-input
                v-model="max"
                id="modal-max"
                :min="mode == 'percentile' ? 0 : null"
                :max="mode == 'percentile' ? 100 : null"
                type="number"
                step=".01"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-modal>
    <table v-if="assets.length > 0" class="table table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th v-if="hasBands > 0">Band(s)</th>
          <th>Roles</th>
          <th>Content-Type</th>
          <th>Asset Key</th>
          <th v-if="isMM">MM</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="asset in assets" :key="asset.key">
          <td>
            <a :href="asset.href" :title="asset.href" v-html="asset.label" />
          </td>
          <td v-if="hasBands">{{ asset.bandNames }}</td>
          <td>
            <b-badge variant="light"> {{ asset.roleNames }}</b-badge>
          </td>
          <td>
            <b-badge v-if="asset.type" v-for="t in parseType(asset.type)" :key="t" variant="light">{{ t }}</b-badge>
          </td>
          <td>
            <code>{{ asset.key }}</code>
          </td>
          <td v-if="isMM">
            <b-button
              size="sm"
              variant="outline-light"
              @click="mappamundi(asset, '')"
              title="Go to Mappamundi"
              v-if="asset.ismm && listening.length == 0"
              :disabled="asset_status[asset.key]"
            >
              <b-icon icon="map" variant="secondary" aria-hidden="true" v-if="!asset_status[asset.key]"></b-icon>
              <b-spinner small label="Loading..." class="ml-auto" variant="dark" v-else></b-spinner>
            </b-button>

            <b-dropdown
              right
              text="Menu"
              size="sm"
              variant="outline-light"
              v-if="asset.ismm && listening.length > 0"
              :disabled="asset_status[asset.key]"
            >
              <template slot="button-content">
                <b-icon icon="map" variant="secondary" aria-hidden="true" v-if="!asset_status[asset.key]"></b-icon>
                <b-spinner small label="Loading..." class="ml-auto" variant="dark" v-else></b-spinner>
              </template>
              <template v-for="(l, i) in listening_unique">
                <b-dropdown-item @click="mappamundi(asset, l)">
                  <b-icon icon="circle-fill" aria-hidden="true" :style="'color:' + l"></b-icon>
                  Map {{ i + 1 }}
                </b-dropdown-item>
              </template>
              <b-dropdown-item @click="mappamundi(asset, '')">
                <b-icon icon="plus-circle-fill" aria-hidden="true" :style="'color:' + getNextColor()"></b-icon>
                New Map
              </b-dropdown-item>
            </b-dropdown>
            <b-button size="sm" variant="light" v-if="asset.ismm" v-b-modal.modal-1 @click="prepareMappamundi(asset)">
              <b-icon icon="palette-fill" v-if="hasConfig(asset)"></b-icon>
              <b-icon icon="palette" v-if="!hasConfig(asset)"></b-icon>
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="assets.length == 0 && source != ''">
      <p>
        There are no assets to render for this source. The base url is:
      </p>
      <div style="overflow: scroll">
        <code>{{ base_uri }}</code>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from '../services/auth-header'
import { MM_API_URL, MM_VIEWER_URL } from '../config'
import colors from '/public/colors.json'

export default {
  name: 'AssetTab',
  props: ['assets', 'bands', 'hasBands', 'item_id', 'collection', 'source', 'base_uri'],
  data() {
    return {
      layerWindow: undefined,
      bc: undefined,
      listening_unique: [],
      listening: [],
      colors,
      color: null,
      min: 0,
      max: 1,
      mode: 'min/max',
      currentAsset: null
    }
  },
  computed: {
    isMM() {
      return this.assets.some((a) => a.ismm)
    },
    asset_status() {
      let r = {}
      this.assets.forEach((a) => {
        r[a.key] = false
      })
      return r
    }
  },
  methods: {
    prepareMappamundi(asset) {
      this.currentAsset = asset

      const key = `${this.collection}.${this.source}.${this.currentAsset?.key}`
      const val = localStorage.getItem(key)
      if (val) {
        const settings = JSON.parse(val)
        console.log('loaded settings', settings)
        this.min = settings.min
        this.max = settings.max
        this.mode = settings.mode
        this.color = settings.color
      } else {
        this.mode = "percentile"
        this.min = 0.1
        this.max = 99.9
      }
    },

    onChangeMode() {
      if (this.mode == "percentile") {
        this.min = 0.1
        this.max = 99.9
      } else {
        this.min = 0
        this.max = 1
      }
    },

    hasConfig(asset) {
      const key = `${this.collection}.${this.source}.${asset.key}`
      const val = localStorage.getItem(key)
      return val != null
    },

    saveAssetConfig() {
      //store to local config
      const key = `${this.collection}.${this.source}.${this.currentAsset.key}`
      localStorage.setItem(
        key,
        JSON.stringify({
          color: this.color,
          min: Number(this.min),
          max: Number(this.max),
          mode: this.mode
        })
      )

      this.currentAsset = null
      this.color = null
      this.min = 0
      this.max = 1
    },

    async mappamundi(asset, color) {
      if (color != null && color != '') {
        this.bc = new BroadcastChannel(color)
      } else {
        color = this.getNextColor()
      }
      var bc = new BroadcastChannel(color)
      this.disable(asset)

      const key = `${this.collection}.${this.source}.${asset.key}`
      const settings = localStorage.getItem(key)

      // Create Mappamundi Layer
      let mm_api_url = new URL(MM_API_URL)
      let layer = {
        uri: asset.href,
        bbox: asset.bbox.split(',').map(Number),
        options: { duration: '1w', minZoom: 4 }
      }

      if (settings) {
        const parsedSettings = JSON.parse(settings)

        layer.colorize = parsedSettings.color

        const scaling = {}

        if (parsedSettings.mode == 'min/max') {
          scaling.min = parsedSettings.min
          scaling.max = parsedSettings.max
        } else {
          scaling.percentile_lower = parsedSettings.min
          scaling.percentile_upper = parsedSettings.max
        }

        layer.scaling = scaling
      }

      let create_layer = await fetch(mm_api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          ...authHeader()
        },
        body: JSON.stringify(layer)
      })
      if (!create_layer.ok) {
        console.warn(await create_layer.text())
        this.enable(asset)
        return
      }
      const data = await create_layer.json()

      // Presign Mappamundi URL
      let sign_layer = await fetch(`${mm_api_url}${data.id}/presign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          ...authHeader()
        },
        body: JSON.stringify({ expiration: '1w' })
      })
      if (!sign_layer.ok) {
        console.warn(await sign_layer.text())
        this.enable(asset)
        return
      }

      // Open Url in new tab
      const data_signed = await sign_layer.json()
      const viewer_url = new URL(window.location.origin + '/viewer')

      viewer_url.searchParams.append('url', data_signed.url)
      viewer_url.searchParams.append('bbox', asset.bbox)
      viewer_url.searchParams.append('name', this.item_id + '_' + asset.key)
      viewer_url.searchParams.append('color', color)
      viewer_url.searchParams.append('channel', color)

      var isOpen = this.getListening().find((c) => c == color)
      if (!isOpen) {
        try {
          this.$root.mmwindow = window.open(viewer_url, '_blank')
        } catch {}
        this.enable(asset)
        return
      }

      bc.postMessage(
        {
          bbox: asset.bbox,
          url: data_signed.url,
          name: this.item_id + '_' + asset.key
        },
        '*'
      )

      try {
        bc.focus()
      } catch {}
      this.enable(asset)
      bc.close()
    },
    parseType(type) {
      if (type) {
        return type.split(';')
      }
      return []
    },
    disable(asset) {
      this.$set(this.asset_status, asset.key, true)
      this.$forceUpdate()
    },
    enable(asset) {
      this.$set(this.asset_status, asset.key, false)
      this.$forceUpdate()
    },
    getListening() {
      if (localStorage['listening']) {
        // send broadcast, wait for ack
        return JSON.parse(localStorage['listening'])
      }
      return []
    },
    getListeningUnique() {
      return [...new Set(this.getListening())]
    },
    getNextColor() {
      var c = ['#FF595E', '#FFCA3A', '#8AC926', '#1982C4', '#6A4C93']
      return c.filter((col) => !this.getListeningUnique().includes(col))[0]
    },
    setListening() {
      this.listening = this.getListening()
    },
    setListening_unique() {
      this.listening_unique = this.getListeningUnique()
    },
    removeListening(color) {
      localStorage['listening'] = JSON.stringify(this.getListeningUnique().filter((c) => c != color))
    }
  },
  mounted() {
    this.setListening()
    this.setListening_unique()

    window.addEventListener('storage', () => {
      this.setListening()
      this.setListening_unique()
      this.$forceUpdate()
    })

    if (this.isMM)
      this.getListeningUnique().forEach((c) => {
        var bc = new BroadcastChannel(c)
        bc.postMessage(
          {
            liveliness: true,
            ack_channel: c + '_ack'
          },
          '*'
        )
        var bc_ack = new BroadcastChannel(c + '_ack')
        var isOpen = false
        bc_ack.onmessage = function (event) {
          if (!event.isTrusted) return
          if (event.data.hasOwnProperty('alive') && event.data.alive) {
            isOpen = true
            bc.close()
            bc_ack.close()
          }
        }
        var _this = this
        setTimeout(function () {
          if (!isOpen) {
            if (_this.getListeningUnique().find((_c) => _c == c)) _this.removeListening(c)
          }
          bc.close()
          bc_ack.close()
        }, 1000)
      })
  },
  beforeDestroy() {
    if (this.bc) {
      this.bc.close()
    }
  }
}
</script>

<style src="./palettes.css"></style>

<style scoped>
.badge {
  font-weight: 200;
}
.btn-group,
.btn-group-vertical {
  vertical-align: baseline;
}
.colors {
  padding: 5px;
}

.colors span {
  background: white;
  padding: 1px;
}

option span:checked{
  color: red;
}
</style>
