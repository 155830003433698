<template>
  <b-navbar type="light" variant="light" class="">
    <b-navbar-brand tag="h1" class="mb-0" href="/">
      <div class="cb-title-container" align-h="center">
        <div class="cb-title">
          <h1><b>ᴄᴀᴛᴀʟᴏɢ</b></h1>
          <small class="align-self-end">ʙʀᴏᴡsᴇʀ</small>
        </div>
      </div>
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto navbar-expand">
      <b-nav-item>
        <b-button size="sm" pill variant="outline-light" title="Open via API" @click="openAPI()">
          <b-icon icon="arrow-up-right-circle" variant="secondary" aria-hidden="true"></b-icon>
        </b-button>
      </b-nav-item>
      <b-nav-item-dropdown variant="outline-light" right>
        <template slot="button-content">
          <b-badge variant="dark">{{ getCurrentEnv() }}</b-badge>
        </template>
        <b-dropdown-item :active="getCurrentEnv() == backend.env" @click="selectEnv(backend.env)"  v-for="backend in this.backends" :key="backend.env"> 
          <div class="d-flex align-items-center">
            <span class="mr-2">{{ backend.description }} </span>
            <small><b-badge variant="dark">{{ backend.env }}</b-badge></small>
          </div>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item>
        <b-button size="sm" pill variant="outline-light" @click.prevent="logout" title="Logout">
          <b-icon icon="power" variant="secondary" aria-hidden="true"></b-icon>
        </b-button>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { backends } from '../util'
import common from './common'

export default {
  ...common,
  name: 'NavBar',
  data() {
    return {
      backends: backends
    }
  },
  computed: {
    ...common.computed
  },
  methods: {
    ...common.methods,
    initialize() {}
  }
}
</script>

<style lang="css">
a.dropdown-toggle {
  padding-top: 12px !important;
}

.navbar-brand .cb-title > small {
  /* font-size: 2rem !important; */
  position: relative;
  top: -1rem;
  height: 0px;
}
.navbar-brand .cb-title > * {
  display: flex;
  justify-content: flex-end;
}
.navbar-brand .cb-title {
  max-inline-size: min-content;
}
.navbar-brand .cb-title-container {
  text-align: -moz-center;
  text-align: -webkit-center;
}
</style>
